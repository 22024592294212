import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"
import DefaultLayoutSidebar from "../../../components/DefaultLayoutSidebar"

import illustration2x from "../../../images/illustrations/illustration-requirements-purine-analogues@2x.png"

import { theme } from "../../../styles/theme"

const HodgkinLymphoma = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - CAR-T therapy" />

      <CustomNavTitle
        title="CAR-T therapy"
        destination="/requirements/irradiation/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <h3 css={[theme.components.title, theme.components.titleRed]}>
            CAR-T therapy
          </h3>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Indication for irradiated components
          </h4>
          <p>
            Patients undergoing peripheral blood lymphocyte collection for future CAR-T cell reinfusion​.
          </p>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Duration of requirement
          </h4>
          <p>7 days prior to the harvest until 3 months following CAR-T cell infusion (unless other indications determine indefinite use).</p>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default HodgkinLymphoma
